import React, { useState, useMemo, useEffect, useRef } from "react";
import { Select, Button, Modal, Pagination, Input } from "common-components";
import { Breadcrumb } from "components";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { setSelectOptions } from "utils/common";
import { Spin, Tabs } from "antd";
import openNotificationWithIcon from "utils/hooks/useNotification";
import { UiQuestionStatusPage } from "./QuestionStatusPage.style";
import { getQuerySeparateRecordSelection } from "api/convert";
import { getChapters } from "api/questionStatus";
import { getReviewQuestions } from "api/questionStatus";
import { unGZip } from "utils/common";
import { isEmpty, mapValues } from "lodash";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { deprecateQuestion } from "api/questionStatus";
import { publishQuestion } from "api/questionStatus";
import moment from "moment";
import { deleteQuestionAIAnalyze } from "api/questionStatus";
import { exportQuestions } from "api/questionStatus";

const QUESTION_REVIEW_STATUS = {
	pending: "Pending",
	approved: "Approved",
	rejected: "Rejected",
};

const QUESTION_REVIEW_STATUS_TABS = [
	{ name: "待審核", value: QUESTION_REVIEW_STATUS.pending },
	{ name: "已上架", value: QUESTION_REVIEW_STATUS.approved },
	{ name: "已下架", value: QUESTION_REVIEW_STATUS.rejected },
];

const DEFAULT_REVIEW_STATUS = QUESTION_REVIEW_STATUS.pending;

const joinMetadata = (metadata) =>
	Array.isArray(metadata)
		? metadata.length > 1
			? metadata.map(({ name }) => `「${name}」`).join("、")
			: metadata[0].name
		: null;

const QUESTION_ERROR_TYPE = {
	Content: "試題內容有誤",
	Answer: "答案有誤",
	Range: "超出課綱範圍",
	Suggestion: "試題修改建議",
	Management: "管理人員停用",
	None: "其他",
};

const DEFAULT_REJECT_REASON = Object.keys(QUESTION_ERROR_TYPE)[0];

const AI_ANALYZE_ERROR_TYPE = {
	Content: "解析內容錯誤/計算錯誤",
	InvalidCharacter: "出現異常符號或亂碼",
};

const DEFAULT_DELETE_AI_ANALYZE_REASON = Object.keys(AI_ANALYZE_ERROR_TYPE)[0];

const breadcrumbList = [
	{
		icon: "find_in_page",
		name: "題目審核管理",
		path: "/questionStatus",
	},
];

export const QuestionStatusPage = () => {
	const [showModal, setShowModal] = useState(false);
	const selectedQuestion = useRef();

	const [sendFormData, setSendFormData] = useState({
		education: "",
		subject: "",
		version: "",
		year: "",
		book: "",
		chapter: "",
		source: "",
		keyword: "",
		status: DEFAULT_REVIEW_STATUS,
	});
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [rejectReason, setRejectReason] = useState(DEFAULT_REJECT_REASON);
	const [rejectReasonMessage, setRejectReasonMessage] = useState("");
	const [deleteAIAnalyzeReason, setDeleteAIAnalyzeReason] = useState(DEFAULT_DELETE_AI_ANALYZE_REASON);
	const [showDeleteAiAnalyzeModal, setDeleteAiAnalyzeShowModal] = useState(false);

	const [stateSelect, sendSelect] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getQuerySeparateRecordSelection();
				const {
					data: { bookMap, eduMap, eduSubject, eduVersion, yearMap },
				} = result;
				return {
					bookMap: bookMap ? setSelectOptions(bookMap) : [],
					eduMap: eduMap ? setSelectOptions(eduMap) : [],
					eduSubject: eduSubject || {},
					eduVersion: eduVersion || {},
					yearMap: yearMap ? setSelectOptions(yearMap) : [],
				};
			},
		},
	});
	const { bookMap, eduSubject, eduVersion, eduMap, yearMap } = stateSelect.context.result || {};

	const [chaptersState, sendChaptersEvent] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const result = await getChapters(event.payload);
				return { chapters: result.data.chapters, knowledges: result.data.knowledges };
			},
		},
	});
	const { chapters, knowledges } = chaptersState.context.result || {};

	const sourceOptions = useMemo(
		() =>
			(chapters?.find(({ code }) => code === sendFormData.chapter)?.sources || []).map(({ code, name }) => ({
				value: code,
				name,
			})),
		[chapters, sendFormData.chapter],
	);

	const eduVersionOption = useMemo(() => {
		if (!sendFormData.education || !eduVersion) return [];
		return setSelectOptions(eduVersion[`${sendFormData.education}`]);
	}, [sendFormData.education]);

	const eduSubjectOption = useMemo(() => {
		if (!sendFormData.education || !eduSubject) return [];
		return setSelectOptions(eduSubject[`${sendFormData.education}`]);
	}, [sendFormData.education, eduSubject]);

	const onSelectChange = (key, value) => {
		setSendFormData((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const selectedKnowledgeCodes = useMemo(
		() => chapters?.find(({ code }) => code === sendFormData.chapter)?.knowledges?.map(({ code }) => code) || [],
		[sendFormData.chapter, chapters],
	);

	const fetchChaptersPayload = useMemo(() => {
		const { book, education, subject, version, year } = sendFormData;
		if ([book, education, subject, version, year].every(Boolean)) {
			return {
				Year: year,
				Edu: education,
				Subject: subject,
				Version: version,
				Volume: book,
			};
		}
		return null;
	}, [sendFormData.book, sendFormData.education, sendFormData.subject, sendFormData.version, sendFormData.year]);

	const fetchQuestionPayload = useMemo(
		() => ({
			params: { pagenumber: page, pagesize: 20 },
			body: {
				eduSubject: sendFormData.education + sendFormData.subject,
				questionReviewStatus: sendFormData.status,
				keyword: sendFormData.keyword,
				...(sendFormData.chapter
					? {
							knowledges: selectedKnowledgeCodes,
							source: sendFormData.source,
					  }
					: {
							knowledges: (knowledges || []).map(({ code }) => code),
					  }),
			},
		}),
		[page, sendFormData, selectedKnowledgeCodes, knowledges],
	);

	const [fetchQuestionsEventState, sendQuestionEvent] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				setIsLoading(true);
				const result = await getReviewQuestions(event.payload.params, event.payload.body);
				const { questions, total } = result.data;
				setIsLoading(false);
				return { questions, total };
			},
		},
	});

	const { questions, total } = fetchQuestionsEventState?.context?.result || [];

	const fetchQuestions = () => {
		if (!fetchQuestionPayload.body.eduSubject || !fetchQuestionPayload.body.knowledges.length) {
			return;
		}
		sendQuestionEvent(FetchEventType.Fetch, {
			payload: fetchQuestionPayload,
		});
	};

	const deleteAIAnalyze = async (question) => {
		try {
			const payload = {
				eduSubject: sendFormData.education + sendFormData.subject,
				uid: question.uid,
				aiAnalyzeErrorType: deleteAIAnalyzeReason,
			};
			const res = await deleteQuestionAIAnalyze(payload);
			const status = res.data ? "success" : "error";
			const message = res.data ? "刪除成功" : "刪除失敗";
			openNotificationWithIcon(status, message);
			if (res.data) {
				fetchQuestions();
				setDeleteAiAnalyzeShowModal(false);
				setDeleteAIAnalyzeReason(DEFAULT_DELETE_AI_ANALYZE_REASON);
			}
		} catch (error) {
			openNotificationWithIcon("error", error.message);
		}
	};

	const deprecate = async (question) => {
		try {
			const payload = {
				eduSubject: sendFormData.education + sendFormData.subject,
				uid: question.uid,
				availability: {
					quick: false,
				},
				questionErrorType: rejectReason,
				message: rejectReasonMessage || QUESTION_ERROR_TYPE[rejectReason],
			};
			const res = await deprecateQuestion(payload);
			const status = res.data ? "success" : "error";
			const message = res.data ? "下架成功" : "下架失敗";
			openNotificationWithIcon(status, message);
			if (res.data) {
				fetchQuestions();
				setShowModal(false);
				setRejectReason(DEFAULT_REJECT_REASON);
				setRejectReasonMessage("");
			}
		} catch (error) {
			openNotificationWithIcon("error", error.message);
		}
	};

	const publish = async (question) => {
		try {
			const payload = {
				eduSubject: sendFormData.education + sendFormData.subject,
				uid: question.uid,
				availability: {
					quick: true,
				},
			};
			const res = await publishQuestion(payload);
			const status = res.data ? "success" : "error";
			const message = res.data ? "上架成功" : "上架失敗";
			openNotificationWithIcon(status, message);
			fetchQuestions();
			setShowModal(false);
		} catch (error) {
			openNotificationWithIcon("error", error.message);
		}
	};

	const handleExport = async () => {
		setIsExporting(true);
		try {
			const { book, chapter, education, source, status, subject, version, year } = sendFormData;
			const eduName = eduMap.find(({ value }) => value === education).name;
			const subjectName = eduSubject[education].find(({ code }) => code === subject).name;
			const versionName = eduVersion[education].find(({ code }) => code === version).name;
			const bookName = bookMap.find(({ value }) => value === book).name;
			const chapterName = chapters.find(({ code }) => code === chapter).name;
			const sourceName = sourceOptions.find(({ value }) => value === source).name;
			const res = await exportQuestions({
				fileName: `${year}-${eduName}${subjectName}-${versionName}${bookName}-${chapterName}-${sourceName}_題目審核管理`,
				eduSubject: education + subject,
				questionReviewStatus: status,
				knowledges: selectedKnowledgeCodes,
				source,
			});
			const { url } = res.data;
			if (url) {
				window.open(url);
			} else {
				openNotificationWithIcon("error", "暫無題目");
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsExporting(false);
		}
	};

	useEffect(() => {
		sendSelect(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (stateSelect.matches(FetchStates.Resolved)) {
			setSendFormData({
				...sendFormData,
				education: eduMap[0].value,
				year: yearMap.at(-1).value,
			});
		}
	}, [stateSelect.value]);

	useEffect(() => {
		onSelectChange("subject", "");
	}, [sendFormData.education]);

	useEffect(() => {
		if (fetchChaptersPayload) {
			sendChaptersEvent(FetchEventType.Fetch, { payload: fetchChaptersPayload });
		}
	}, [fetchChaptersPayload]);

	useEffect(() => {
		onSelectChange("chapter", "");
	}, [chapters]);

	useEffect(() => {
		onSelectChange("source", "");
	}, [sourceOptions]);

	useEffect(() => {
		fetchQuestions();
	}, [sendFormData.status, page]);

	useEffect(() => {
		setPage(1);
	}, [sendFormData.status]);

	useEffect(() => {
		setRejectReasonMessage("");
	}, [rejectReason]);

	return (
		<>
			<UiQuestionStatusPage>
				<div className="breadBlock">
					<Breadcrumb data={breadcrumbList} />
				</div>
				<div className="searchToolBar">
					<div className="selectSearchGroup">
						<Select
							options={yearMap}
							value={sendFormData.year}
							onChange={(val) => onSelectChange("year", val)}
							allowClear={false}
						/>
						<Select
							options={eduMap}
							value={sendFormData.education}
							onChange={(val) => onSelectChange("education", val)}
							allowClear={false}
						/>
						<Select
							options={eduSubjectOption}
							value={sendFormData.subject}
							onChange={(val) => onSelectChange("subject", val)}
							allowClear={false}
						/>
						<Select
							options={eduVersionOption}
							value={sendFormData.version}
							onChange={(val) => onSelectChange("version", val)}
							allowClear={false}
						/>
						<Select
							options={bookMap}
							value={sendFormData.book}
							onChange={(val) => onSelectChange("book", val)}
							allowClear={false}
						/>
						<Select
							options={setSelectOptions(chapters)}
							value={sendFormData.chapter}
							onChange={(val) => onSelectChange("chapter", val)}
						/>
						<Select
							options={sourceOptions}
							value={sendFormData.source}
							onChange={(val) => onSelectChange("source", val)}
						/>
						<Input
							placeholder="搜尋試題內容"
							value={sendFormData.keyword}
							onChange={(e) => onSelectChange("keyword", e.target.value)}
						/>
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="search"
							onClick={() => {
								fetchQuestions();
								setPage(1);
							}}
							loading={isLoading}
							disabled={!sendFormData.book}>
							搜尋
						</Button.IconButton>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "center", columnGap: "12px" }}>
					<Tabs onTabClick={(key) => onSelectChange("status", key)} style={{ flex: "1" }}>
						{QUESTION_REVIEW_STATUS_TABS.map((tab) => (
							<Tabs.TabPane tab={tab.name} key={tab.value}></Tabs.TabPane>
						))}
					</Tabs>
					<Button.IconButton
						variant="green"
						full={false}
						iconName="save_alt"
						onClick={handleExport}
						loading={isExporting}
						disabled={!sendFormData.chapter || !sendFormData.source}>
						匯出
					</Button.IconButton>
				</div>
				{isLoading ? (
					<div className="loading">
						<Spin indicator={<Loading3QuartersOutlined spin={true} />} size="large" />
					</div>
				) : (
					<div className="questionSearchTableContent">
						<div className="questionSearchTable">
							{questions?.length > 0 ? (
								<>
									<div className="question__container">
										{questions.map((question) => {
											const { aiAnalyze, analyze, answer, content } = mapValues(question.htmlParts, (value) =>
												value ? unGZip(value) : null,
											);
											return (
												<div key={question.uid} className="question__item">
													<div className="question__html">
														{content && <div dangerouslySetInnerHTML={{ __html: content }} />}
														{answer && <div dangerouslySetInnerHTML={{ __html: answer }} />}
														{analyze && <div dangerouslySetInnerHTML={{ __html: analyze }} />}
														{aiAnalyze && <div dangerouslySetInnerHTML={{ __html: aiAnalyze }} />}
													</div>
													<div className="question__infos">
														{[
															{
																label: "出處",
																value: question.source.name,
															},
															{
																label: "CMS 編號",
																value: question.cms,
															},
															{
																label: "知識向度",
																value: joinMetadata(question.knowledge),
															},
															{
																label: "知識點",
																value: joinMetadata(question.knowledgeIndexes),
															},
															{
																label: "核心素養",
																value: joinMetadata(question.core_accomplishment),
															},
															{
																label: "學習表現",
																value: joinMetadata(question.performance),
															},
															{
																label: "學習內容",
																value: joinMetadata(question.learn_content),
															},
															{
																label: "難度",
																value: question.difficulty.name,
															},
															{
																label: "題型",
																value: question.ques_type.name,
															},
															{
																label: "選項數",
																value: question.answerInfos.map(({ optionAmount }) => optionAmount).join("、"),
															},
														]
															.filter(({ value }) => !isEmpty(value))
															.concat(
																sendFormData.status === QUESTION_REVIEW_STATUS.rejected &&
																	question.reviewQuestionInfos.message
																	? [
																			{
																				label: "下架原因",
																				value: question.reviewQuestionInfos.message,
																			},
																			{
																				label: "下架日期",
																				value: moment(question.reviewQuestionInfos.dateTime).format("YYYY-MM-DD"),
																			},
																	  ]
																	: [],
															)
															.map(({ label, value }) => (
																<div key={label} className="question__infos__item">
																	<div className="question__infos__label">{`${label}：`}</div>
																	<div className="question__infos__value">{value}</div>
																</div>
															))}
													</div>
													<div className="question__actions">
														{aiAnalyze && (
															<Button
																full={false}
																onClick={() => {
																	selectedQuestion.current = question;
																	setDeleteAiAnalyzeShowModal(true);
																}}>
																AI解析錯誤
															</Button>
														)}
														{sendFormData.status !== QUESTION_REVIEW_STATUS.approved && (
															<Button full={false} variant="blue" onClick={() => publish(question)}>
																上架
															</Button>
														)}
														{sendFormData.status !== QUESTION_REVIEW_STATUS.rejected && (
															<Button
																full={false}
																variant="red"
																onClick={() => {
																	selectedQuestion.current = question;
																	setShowModal(true);
																}}>
																下架
															</Button>
														)}
													</div>
												</div>
											);
										})}
									</div>
									<Pagination
										total={total}
										defaultCurrent={1}
										current={page}
										onChange={(value) => setPage(value)}
										defaultPageSize={20}
									/>
								</>
							) : (
								<div className="tableNoData">
									<img src={`/assets/noData.png`} alt="noData" />
									無資料顯示
								</div>
							)}
						</div>
					</div>
				)}
				{selectedQuestion.current && (
					<>
						<Modal
							title="下架試題"
							visible={showModal}
							onOk={() => deprecate(selectedQuestion.current)}
							onCancel={() => setShowModal(false)}
							okButtonProps={{ disabled: rejectReason === "None" && !rejectReasonMessage }}>
							<Select
								label="下架原因"
								options={Object.entries(QUESTION_ERROR_TYPE).map(([value, name]) => ({ name, value }))}
								value={rejectReason}
								onChange={(val) => setRejectReason(val)}
							/>
							{rejectReason === "None" && (
								<Input
									type="text"
									placeholder="原因"
									value={rejectReasonMessage}
									onChange={(e) => setRejectReasonMessage(e.target.value)}
								/>
							)}
						</Modal>
						<Modal
							title="刪除 AI 解析"
							visible={showDeleteAiAnalyzeModal}
							onOk={() => deleteAIAnalyze(selectedQuestion.current)}
							onCancel={() => setDeleteAiAnalyzeShowModal(false)}>
							<Select
								label="錯誤原因"
								options={Object.entries(AI_ANALYZE_ERROR_TYPE).map(([value, name]) => ({ name, value }))}
								value={deleteAIAnalyzeReason}
								onChange={(val) => setDeleteAIAnalyzeReason(val)}
							/>
						</Modal>
					</>
				)}
			</UiQuestionStatusPage>
		</>
	);
};
